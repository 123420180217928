import $ from 'jquery';
import 'jquery-ajaxit';
import indevGtag from 'analytics/tracker';

const $contactForm = $('.contact-form');

const submitButton = $contactForm.find('button[type="submit"]');

$contactForm.ajaxit({
	onSuccess: function(data) {
		submitButton.text(data.message);
		$contactForm.find(':input').prop('disabled', true);
		indevGtag.sendEvent('contact', {
			'event_category': 'form_submit',
			'event_label': '(not set)',
		});
	},
	onValidationError: function(data) {
		const errorlist = $contactForm.find('.recaptcha-error .errorlist');

		if ('recaptcha_errors' in data) {
			$(data.recaptcha_errors).each((_, error) => {
				errorlist.append(`<li>${error}</li>`);
			});
		}

		indevGtag.sendEvent('contact', {
			'event_category': 'form_error',
			'event_label': '(not set)',
		});
	},
});
