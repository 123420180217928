import $ from 'jquery';
import 'jquery-ajaxit';
import ezps from 'ezps';
import indevGtag from 'analytics/tracker';

const $lightboxTrigger = $('.js-corporate-contact');

const options = {
	isClickableElement: () => true,
};

const events = {
	afterChange: function() {
		const $container = $('.pswp--open .corporate-contact-container');

		$container.find('form').ajaxit({
			onSuccess: function() {
				$container.addClass('success');

				$('html, body').animate({
					scrollTop: $container.offset().top - 50,
				});

				indevGtag.sendEvent('contact', {
					'event_category': 'form_submit',
					'event_label': '(not set)',
				});
			},
			onValidationError: function() {
				$('html, body').animate({
					scrollTop: $container.offset().top - 50,
				});
				indevGtag.sendEvent('contact', {
					'event_category': 'form_error',
					'event_label': '(not set)',
				});
			},
		});
	},
};

if ($lightboxTrigger.length > 0) {
	ezps($lightboxTrigger, options, events);
}
