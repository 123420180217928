import $ from 'jquery';
import 'jquery-ajaxit';

const $form = $('.js-admin-form');

const $membershipAction = $('.field.admin-renewal input[type="checkbox"]');
const $formsWrapper = $('.membership-cash-wrapper');

$form.ajaxit({
	getResponseStatus: function(data) {
		return data.action === 'redirect' ? 'success' : 'errors';
	},
	onSuccess: function(data) {
		window.location = data.url;
	},
});

$membershipAction.on('change', function(event) {

	const $box = $(event.currentTarget);

	if ($box.is(':checked')) {
		$formsWrapper.addClass('renewal');
	} else {
		$formsWrapper.removeClass('renewal');
	}
});


