import $ from 'jquery';
import 'slick-carousel';
import indevGtag from 'analytics/tracker';

// Attach photoswipe markup to slideshow.
const $slideshow = $('.slideshow');

if ($slideshow.length > 0) {
	$('.slideshow .more').click(function() {
		const elem = $(event.currentTarget);
		const label = elem.attr('data-slug');
		indevGtag.sendEvent('hero', {
			'event_category': 'slideshow_click',
			'event_label': `${label} | more`,
		});
	});

	$('.slideshow .livestream').click(function() {
		const elem = $(event.currentTarget);
		const label = elem.attr('data-slug');
		indevGtag.sendEvent('hero', {
			'event_category': 'slideshow_click',
			'event_label': `${label} | buy_stream`,
		});
	});

	$('.slideshow .vod').click(function() {
		const elem = $(event.currentTarget);
		const label = elem.attr('data-slug');
		indevGtag.sendEvent('hero', {
			'event_category': 'slideshow_click',
			'event_label': `${label} | buy_vod`,
		});
	});

	$slideshow.slick();

	$('.slick-prev').click(function() {
		let activeSlide = $('.slick-active').next().find('.more');
		let label = activeSlide.attr('data-slug');
		indevGtag.sendEvent('hero', {
			'event_category': 'slideshow_click',
			'event_label': `${label} | prev`,
		});
	});

	$('.slick-next').click(function() {
		let activeSlide = $('.slick-active').prev().find('.more');
		let label = activeSlide.attr('data-slug');
		indevGtag.sendEvent('hero', {
			'event_category': 'slideshow_click',
			'event_label': `${label} | next`,
		});
	});
}
