import indevGtag from 'analytics/tracker';

const livestreamDonation = document.querySelector('.js-livestream-donation');
if (livestreamDonation) {
	livestreamDonation.addEventListener('click', function() {
		indevGtag.sendEvent('live stream donation', {
			'event_category': 'button_click',
			'event_label': 'donation',
		});
	});
}
