import React from 'react';
import moment from 'moment';

import Day from 'calendar/components/day';
import DayDetails from 'calendar/components/day-details';
import {getCalendarMonthDays} from 'calendar/utils';


export default class Calendar extends React.Component {
	constructor(props) {
		super(props);

		const nextOccurrence = this.props.scheduler.firstDayAfter(moment());

		let month = moment().startOf('month');
		if (nextOccurrence) {
			const nextOccurrenceMonth = nextOccurrence.startOf('month');
			month = nextOccurrenceMonth > month ? nextOccurrenceMonth : month;
		}

		this.state = {
			month: month,
			focusedDay: null,
		};

		// bind methods
		this.prevMonth = this.prevMonth.bind(this);
		this.nextMonth = this.nextMonth.bind(this);
		this.focusDay = this.focusDay.bind(this);
		this.unfocusDay = this.unfocusDay.bind(this);
	}

	prevMonth() {
		this.setState({
			month: this.state.month.clone().subtract({month: 1}),
		});
	}
	nextMonth() {
		this.setState({
			month: this.state.month.clone().add({month: 1}),
		});
	}
	focusDay(day) {
		this.setState({focusedDay: day});
	}
	unfocusDay() {
		this.setState({focusedDay: null});
	}

	render() {
		const days = getCalendarMonthDays(this.state.month);
		let dayDetails = null;
		let wrapperClass = 'calendar-container';
		if (this.state.focusedDay !== null) {
			dayDetails = <DayDetails
				onClose={this.unfocusDay}
				onFocusDay={this.focusDay}
				day={this.state.focusedDay}
				scheduler={this.props.scheduler}
				showLinks={this.props.showLinks}
			/>;

			wrapperClass = `${wrapperClass} active`;
		}

		let renderedMonth = <div className="month">
			<button className="prev-month" onClick={this.prevMonth} />
			<button className="next-month" onClick={this.nextMonth} />
			{this.state.month.format('MMMM YYYY')}
		</div>;

		return <div>
			<div className={wrapperClass}>
				<div className="responsive-title">
					<div className="text">ΗΜΕΡΟΛΟΓΙΟ ΠΑΡΑΣΤΑΣΕΩΝ</div>
				</div>
				<div className="calendar">
					{this.props.children}

					{renderedMonth}

					<ul className="days names">
						{this.renderDayNames()}
					</ul>
					<ul className="days">
						{days.map(day => this.renderDay(day))}
					</ul>
					<ul className="legend">
						<li className="local">Στο Θέατρο</li>
						<li className="livestream">Live Streaming</li>
					</ul>
				</div>
			</div>

			{dayDetails}
		</div>;
	}

	renderDay(day) {
		return <Day
			day={day}
			scheduler={this.props.scheduler}
			activeMonth={this.state.month}
			onClick={this.focusDay}
			key={day.unix()}
		/>;
	}

	renderDayNames() {
		const names = moment.weekdaysMin(true);
		return names.map(name => <li key={name}>{name}</li>);
	}
}
Calendar.defaultProps = {
	showLinks: true,
};
