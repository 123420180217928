import $ from 'jquery';
import 'jquery-ajaxit';
import ezps from 'ezps';

const menuLogin = '#js-menu-login';

// Should be executed before ezps
function shouldRedirect() {
	const home = document.querySelector('body.homepage');

	if (home) {
		const getParams = window.location.search.split('&');

		for (const param of getParams) {
			if (param.indexOf('next=') > -1) {
				const menuItem = home.querySelector(menuLogin);

				if (menuItem) {
					const inputs = menuItem.querySelector('input[name="next"]');
					inputs.value = param.split('=')[1];
					// Once we've set the "next" attr it's safe to return
					return true;
				}
				return false;
			}
		}
		// No redirect
		return false;
	}
}

function setupLoginForm($form) {
	$form.ajaxit({
		getResponseStatus: function(data) {
			if (data.status === 'redirect') {
				return 'success';
			} else {
				return data.status;
			}
		},
		onSuccess: function(data) {
			if (data.status === 'success') {
				window.location.reload(false);
			} else if (data.status === 'redirect') {
				window.location.href = decodeURIComponent(data.url);
			}
		},
	});
}

function setupResetForm($formContainer) {
	$formContainer.find('form').ajaxit({
		onSuccess: function(data) {
			$formContainer.addClass('success');
			$formContainer.find('.email-placeholder').html(data.email);
		},
	});
}

const events = {
	afterChange: function() {
		const body = $('body');
		const $loginForm = $('.pswp--open #js-login-form');
		const $resetFormContainer = $('.pswp--open .password-reset-form');
		const $next = $loginForm.find('input[name="next"]');

		setupLoginForm($loginForm);
		setupResetForm($resetFormContainer);

		body.addClass('login-open');
		if (body.hasClass('menu-open')) {
			body.removeClass('menu-open');
		}

		$loginForm.find('.reset-button').on('click', function(event) {
			event.preventDefault();
			$loginForm.closest('.login-form').addClass('hide');
			$resetFormContainer.removeClass('hide');
		});

		// Login required button
		const button = $('.pswp--open .login-required-form .login-button');
		button.on('click', function(event) {
			const $el = $(event.target);
			$el.closest('.login-required-form').addClass('hide');
			$el.closest('.login-form').removeClass('hide');
		});

		const backButton = $resetFormContainer.find('.back-button');
		backButton.on('click', function() {
			$resetFormContainer.addClass('hide');
			$loginForm.closest('.login-form').removeClass('hide');
		});

		// Check for passed next url as data attribute
		const nextUrl = $('.js-open-login.clicked').data('next');
		if (nextUrl) {
			$next.val(nextUrl);
		}
	},
	close: function() {
		$('body').removeClass('login-open');
		$('.js-open-login').removeClass('clicked');
	},
};

const options = {
	isClickableElement: () => true,
	arrowKeys: false,
};

// Check for login redirections (before ezps is initialized)
const isRedirect = shouldRedirect();
const loginTemplate = $('#js-menu-login [data-ezps-content]');
const $openLogin = $('.js-open-login');

// Function that (re)initializes all ezps items of lists
function initializeItems($parent) {
	const membershipSelector = '.membership-required';
	const loginSelector = '.login-required';

	let $membershipRequired = $(membershipSelector);
	let $loginRequired = $(loginSelector);

	// If a parent is given only (re)initialize child elements of that parent
	if (typeof $parent !== 'undefined') {
		$membershipRequired = $parent.find(membershipSelector);
		$loginRequired = $parent.find(loginSelector);
	}

	// Initialize items
	$membershipRequired.each(function(_, elem) {
		ezps($(elem));
	});

	$loginRequired.each(function(_, elem) {
		ezps($(elem), options, events);
	});
}

// Initialize menu login button
ezps(menuLogin, options, events);

// Initialize login/membership required items
initializeItems();

if (loginTemplate.length > 0) {
	ezps($openLogin, {
		ezpsContent: loginTemplate[0].innerHTML,
		isClickableElement: () => true,
	}, events);
}

$openLogin.on('click', function(event) {
	// Add 'clicked' class to be consumed by next URL
	$(event.target).addClass('clicked');
});

if (isRedirect) {
	// If we want to redirect, click the login button
	$(menuLogin).click();
}

export {initializeItems as default};
