const purchaseContainer = document.querySelector('.js-purchases');

if (purchaseContainer) {
	const purchaseFilterElement = document.querySelector('.js-purchase-filter');
	const purchases = [...purchaseContainer.querySelectorAll('li')];

	purchaseFilterElement.addEventListener('change', event => {
		const value = event.currentTarget.value;
		purchases.map(element => {
			if (value === 'all') {
				element.classList.remove('hidden');
			} else {
				if (element.dataset.type !== value) {
					element.classList.add('hidden');
				} else {
					element.classList.remove('hidden');
				}
			}
		});
	});
}
