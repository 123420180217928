const NOT_SET = '(not set)';

function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
		let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

export default class IndevGtag {
	constructor(trackerID, gtagParams, userParams) {
		const _defaultParams = {
			'transport_type': 'beacon',
		};

		this.trackerID = trackerID;
		this.gtagParams = Object.assign({}, _defaultParams, gtagParams);
		this.userParams = Object.assign({}, userParams);

		this.gtag = this.setup(this.trackerID);
	}

	setup(trackerID) {
		let script = document.createElement('script');
		let src = `https://www.googletagmanager.com/gtag/js?id=${trackerID}`;
		script.src = src;
		document.getElementsByTagName('head')[0].appendChild(script);
		window.dataLayer = window.dataLayer || [];

		function gtag() {
			window.dataLayer.push(arguments);
		}

		const data = Object.assign({}, this.gtagParams, this.persistent);
		gtag('js', new Date());
		gtag('config', trackerID, data);

		return gtag;
	}

	sendPageView(data) {
		const gtagParams = Object.assign({}, data, this.gtagParams);
		const persistentData = Object.assign({}, this.persistent);
		const configData = Object.assign({}, gtagParams, persistentData);

		this.gtag('config', this.trackerID, configData);
	}

	sendEvent(action, data) {
		data = data || {};
		const defaults = {
			'event_category': NOT_SET,
			'event_label': NOT_SET,
		};

		data = Object.assign({}, defaults, data, this.persistent);

		this.gtag('event', action, data);
	}

	//TODO change name and make it function instead of getter
	get persistent() {
		let data = {};
		const persistent = this.userParams.persistent;
		for (let key in persistent) {
			// check if key exist in custom map
			if (key in this.gtagParams.custom_map) {
				if (persistent[key] === 'uuid') {
					data[this.gtagParams.custom_map[key]] = uuidv4();
				}
			}
		}
		return Object.assign({}, data, this.userId);
	}
}
