import indevGtag from 'analytics/tracker';

const $playsInfoTabs = document.querySelector('.play-section .info-tabs');
if ($playsInfoTabs) {
	const links = $playsInfoTabs.querySelectorAll('li a');
	links.forEach(link => {
		link.addEventListener('click', e => {
			let label;
			if (e.target.classList.contains('anaytics-play-program')) {
				label = 'Πρόγραμμα';
			} else if (e.target.classList.contains('anaytics-play-support')) {
				label = 'Στήριξέ μας';
			} else if (e.target.classList.contains('analytics-play-archive')) {
				label = 'Αρχείο Παράστασης';
			}

			indevGtag.sendEvent('Υλικό παράστασης', {
				'event_category': 'button_click',
				'event_label': label,
			});
		});
	});
}
