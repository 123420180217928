import $ from 'jquery';
import ezps from 'ezps';

const $body = $(document.body);
const $profileMenuDesktop = $('.logged-in-menu.desktop');
const $profileMenuMobile = $('.logged-in-menu.mobile');

$('.trigger').on('click', function() {
	$body.toggleClass('menu-open');

	if ($profileMenuDesktop.hasClass('open')) {
		$profileMenuDesktop.removeClass('open');
	}
});

if ($profileMenuMobile.length > 0) {
	const events = {
		afterChange: function() {
			if ($body.hasClass('menu-open')) {
				$body.removeClass('menu-open');
			}
			$('.ezps-slide-wrapper .logged-lightbox-container')
				.addClass('open');
		},
	};
	ezps($profileMenuMobile, {}, events);
}
