function generateDays(start, count) {
	/* generate `count` days starting with (and including) `start` */
	let days = [start.clone()];
	while (days.length < count) {
		const last = days[days.length - 1];
		days.push(last.clone().add({days: 1}));
	}
	return days;
}

export function getCalendarMonthDays(startOfMonth) {
	/* get the days needed to show a calendar month */
	// start frin the first day of the week of the first day of the month
	const start = startOfMonth.clone().startOf('week');

	// calculate enough days for 6 weeks
	return generateDays(start, 6 * 7);
}
