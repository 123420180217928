import setupPaymentForm from 'payment-form';
import indevGtag from 'analytics/tracker';


const formatter = new Intl.NumberFormat('el-GR', {
	style: 'currency',
	currency: 'EUR',
	currencyDisplay: 'code',
});


function getPrice(performance, viewers) {
	let price = (
		parseFloat(performance.price.replace(',', '.')) +
		(parseInt(viewers, 10) -1) *
		parseFloat(performance.extraViewerPrice.replace(',', '.'))
	);

	return formatter.format(price).replace(
		/[a-z]{3}/i, ''
	).trim();
}

function getViewers(viewerElem, checkout) {
	// Default viewer is always 1
	if (checkout === 'viva') {
		return 1;
	}
	return viewerElem ? viewerElem.value : 1;
}

function getCheckoutOption(checkoutOptions) {
	const selected = [...checkoutOptions].find(radio => radio.checked);
	return selected.value;
}

export function parsePerformances(performanceSelect) {
	const performances = new Map();

	for (const performanceOption of performanceSelect.children) {
		if (performanceOption.value === '') {
			continue;
		}

		const id = performanceOption.value;
		performances.set(id, {
			price: performanceOption.dataset.price,
			extraViewerPrice: performanceOption.dataset.extraViewerPrice,
			label: performanceOption.dataset.label,
			startsAt: performanceOption.dataset.startsAt,
		});
	}

	return performances;
}

const form = document.querySelector('.js-livestream-checkout');
if (form) {
	const performanceTitle = form.querySelector('.js-title');
	const performanceSelect = form.querySelector('.field.performance select');
	const viewersElement = form.querySelector('.js-viewers');
	const viewersContainer = form.querySelector('.viewers.field-wrapper');
	const viewerSubtitle = form.querySelectorAll('.viewers-subtitle');
	const priceElement = form.querySelector('.js-price');
	const priceViewersContainer = form.querySelector('.price-title');
	const priceViewers = form.querySelector('.js-price-viewers');
	const packageContainer = form.querySelector('.pay-bottom .text');
	const packageElement = packageContainer.querySelector('.package');
	const checkoutOptions = form.querySelectorAll('input[type=radio]');
	const paymentInfoElement = form.querySelector('.payment-information');
	const vivaTicketElement = form.querySelector('.viva_ticket.field-wrapper');
	const vivaTicketPrice = form.querySelector('.viva-ticket-price');
	const submitButton = form.querySelector('button');
	const membershipBenefitPrice = form.querySelector('.free-quotas');

	const performances = parsePerformances(performanceSelect);
	const hasFreeLivestream = JSON.parse(form.dataset.hasFreeLivestream);

	// Fix initial checkout render
	for (const option of checkoutOptions) {
		if (option.checked) {
			option.parentNode.classList.add('checked');
		}
	}

	function render(performance) {
		let checkout = getCheckoutOption(checkoutOptions);
		let viewers = getViewers(viewersElement, checkout);

		// Handle checkout rendering
		if (checkout === 'viva') {
			// Hide Viewers elements
			[...viewerSubtitle].map(elem => elem.classList.add('hidden'));
			if (viewersContainer) {
				viewersContainer.classList.add('hidden');
			}
			// Hide payment information
			paymentInfoElement.classList.add('hidden');

			// Display viva ticket elements
			vivaTicketElement.classList.remove('hidden');
			vivaTicketPrice.classList.remove('hidden');

			// Update price styles
			priceElement.classList.add('redeem');

			// Hide membership benefits if applicable
			if (membershipBenefitPrice) {
				membershipBenefitPrice.classList.add('hidden');
			}

			// Update CTA text
			submitButton.innerHTML = 'Eξαργύρωση';
		} else {
			// Display viewers
			[...viewerSubtitle].map(
				elem => elem.classList.remove('hidden')
			);
			if (viewersContainer) {
				viewersContainer.classList.remove('hidden');
			}
			// Hide viva ticket elements
			vivaTicketElement.classList.add('hidden');
			vivaTicketPrice.classList.add('hidden');

			// Update price element
			priceElement.classList.remove('redeem');

			// Display payment information if applicable
			if (hasFreeLivestream) {
				paymentInfoElement.classList.add('hidden');
			} else {
				paymentInfoElement.classList.remove('hidden');
			}

			// Hide membership benefits if applicable
			if (membershipBenefitPrice) {
				membershipBenefitPrice.classList.remove('hidden');
			}
			// Handle CTA text
			submitButton.innerHTML = ' Αγορά';
		}

		// Handle selected performance rendering
		if (typeof performance === 'undefined') {
			priceElement.innerHTML = '';
			packageElement.innerHTML = '';
			priceViewersContainer.classList.add('hidden');
			packageContainer.classList.add('no-selection');
		} else {
			let price = getPrice(performance, viewers);
			priceElement.innerHTML = `${price}€`;
			priceViewers.innerHTML = `${viewers}`;
			priceViewersContainer.classList.remove('hidden');

			// Update bottom text
			let performanceInfo = `${performance.label} / ${price}€`;

			let bottomText =
				`${performanceTitle.innerHTML} / ${performanceInfo}`;
			if (hasFreeLivestream) {
				bottomText = `${bottomText} / Δωρεάν Προβολή`;
			}
			packageElement.innerHTML = bottomText;

			packageContainer.classList.remove('no-selection');
		}
	}

	render(
		performances.get(performanceSelect.value),
	);

	performanceSelect.addEventListener('change', function() {
		render(
			performances.get(performanceSelect.value),
		);
	});

	if (viewersElement) {
		viewersElement.addEventListener('change', function() {
			render(
				performances.get(performanceSelect.value),
			);
		});
	}

	// Handle checkout option change
	for (const option of checkoutOptions) {
		option.addEventListener('change', function() {
			render(
				performances.get(performanceSelect.value)
			);
		});
	}

	setupPaymentForm(form, {
		onValidationError: function() {
			let label = performanceTitle.innerHTML;
			const performance = performances.get(performanceSelect.value);
			if (performance) {
				label = `${label} | ${performance.startsAt}`;
			}

			indevGtag.sendEvent('live stream purchase', {
				'event_category': 'form_error',
				'event_label': label,
			});
		},
	});
}
