import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import settings from 'settings';

if (settings.sentry) {
	Sentry.init({
		dsn: settings.sentry.dsn,
		debug: settings.sentry.debug,
		release: settings.sentry.release,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: settings.sentry.trace_sample_rate,
		attachStacktrace: 'on',
	});
}
