import $ from 'jquery';

const $radioButtons = $('input[type="radio"]');

function setData(membership) {
	const $parent = $(membership).parents().closest('li');
	const title = $parent.find('label .title').text();
	const subtitle = $parent.find('.subtitle').text();

	$('.pay-bottom .text').removeClass('no-selection');
	$('.pay-bottom .package').text(`${title} / ${subtitle}`);
}

$radioButtons.each(function(_, el) {
	if ($(el).is(':checked')) {
		setData(el);
	}
});

$radioButtons.on('change', function(event) {
	const $radio = $(event.currentTarget);

	$radio.closest('.field').find('label').removeClass('checked');
	$radio.closest('label').addClass('checked');

	setData($radio);
});

const $form = $('form.js-renew-form');

if ($form.length > 0) {
	const $previousMembership = $form.data('previous-membership');
	if ($previousMembership !== 'free') {
		const $input = $(`input[value=${$previousMembership}]`);

		$input.prop('checked', true);
		$input.closest('label').addClass('checked');

		setData($input);
	}
}
