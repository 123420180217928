import indevGtag from 'analytics/tracker';

const hook = document.querySelector('header');

if (hook) {
	const loginLink = hook.querySelector('.unlogged-item.menu-login');
	if (loginLink) {
		loginLink.addEventListener('click', function() {
			indevGtag.sendEvent('header', {
				'event_category': 'button_click',
				'event_label': 'σύνδεση',
			});
		});
	}

	const registerLink = hook.querySelector('.unlogged-item.menu-register');
	if (registerLink) {
		registerLink.addEventListener('click', function() {
			indevGtag.sendEvent('header', {
				'event_category': 'button_click',
				'event_label': 'εγγραφή',
			});
		});
	}
}
