import setupPaymentForm from 'payment-form';
import indevGtag from 'analytics/tracker';


const formatter = new Intl.NumberFormat('el-GR', {
	style: 'currency',
	currency: 'EUR',
	currencyDisplay: 'code',
});


function getPrice(performance, viewers) {
	let price = (
		parseFloat(performance.price.replace(',', '.')) +
		(parseInt(viewers, 10) -1) *
		parseFloat(performance.extraViewerPrice.replace(',', '.'))
	);

	return formatter.format(price).replace(
		/[a-z]{3}/i, ''
	).trim();
}

function getViewers(viewerElem, checkout) {
	// Default viewer is always 1
	if (checkout === 'viva') {
		return 1;
	}
	return viewerElem ? viewerElem.value : 1;
}

function getCheckoutOption(checkoutOptions) {
	const selected = [...checkoutOptions].find(radio => radio.checked);
	return selected.value;
}

function parsePerformance(performanceElement) {
	return {
		price: performanceElement.dataset.price,
		extraViewerPrice: performanceElement.dataset.extraViewerPrice,
		label: performanceElement.dataset.play,
	};
}


const form = document.querySelector('.js-vod-checkout');
if (form && form.dataset.isPurchased === 'false') {
	const viewersElement = form.querySelector('.js-viewers');
	const viewersContainer = form.querySelector('.viewers.field-wrapper');
	const viewerSubtitle = form.querySelectorAll('.viewers-subtitle');

	const priceElement = form.querySelector('.js-price');
	const priceViewersContainer = form.querySelector('.price-title');
	const priceViewers = form.querySelector('.js-price-viewers');

	const packageContainer = form.querySelector('.pay-bottom .text');
	const packageElement = packageContainer.querySelector('.package');

	const checkoutOptions = form.querySelectorAll('input[type=radio]');
	const paymentInfoElement = form.querySelector('.payment-information');
	const vivaTicketElement = form.querySelector('.viva_ticket.field-wrapper');
	const vivaTicketPrice = form.querySelector('.viva-ticket-price');

	const submitButton = form.querySelector('button');

	const membershipBenefitPrice = form.querySelector('.free-quotas');
	const performance = parsePerformance(form);
	const hasFreeVideoOnDemand = JSON.parse(form.dataset.hasFreeVideoOnDemand);

	// Fix initial checkout render
	for (const option of checkoutOptions) {
		if (option.checked) {
			option.parentNode.classList.add('checked');
		}
	}

	function render() {
		let checkout = getCheckoutOption(checkoutOptions);
		let viewers = getViewers(viewersElement, checkout);

		// Handle checkout rendering
		if (checkout === 'viva') {
			// Hide Viewers elements
			[...viewerSubtitle].map(elem => elem.classList.add('hidden'));
			if (viewersContainer) {
				viewersContainer.classList.add('hidden');
			}
			// Hide payment information
			paymentInfoElement.classList.add('hidden');

			// Display viva ticket elements
			vivaTicketElement.classList.remove('hidden');
			vivaTicketPrice.classList.remove('hidden');

			// Update price styles
			priceElement.classList.add('redeem');

			// Hide membership benefits if applicable
			if (membershipBenefitPrice) {
				membershipBenefitPrice.classList.add('hidden');
			}
			// Update CTA text
			submitButton.innerHTML = 'Eξαργύρωση';
		} else {
			// Display viewers
			[...viewerSubtitle].map(
				elem => elem.classList.remove('hidden')
			);
			if (viewersContainer) {
				viewersContainer.classList.remove('hidden');
			}
			// Hide viva ticket elements
			vivaTicketElement.classList.add('hidden');
			vivaTicketPrice.classList.add('hidden');

			// Update price element
			priceElement.classList.remove('redeem');

			// Display payment information if applicable
			if (hasFreeVideoOnDemand) {
				paymentInfoElement.classList.add('hidden');
			} else {
				paymentInfoElement.classList.remove('hidden');
			}

			// Hide membership benefits if applicable
			if (membershipBenefitPrice) {
				membershipBenefitPrice.classList.remove('hidden');
			}
			// Handle CTA text
			submitButton.innerHTML = ' Αγορά';
		}

		let price = getPrice(performance, viewers);
		priceElement.innerHTML = `${price}€`;
		priceViewers.innerHTML = `${viewers}`;
		priceViewersContainer.classList.remove('hidden');

		// Update bottom text
		let bottomText;
		if (!hasFreeVideoOnDemand) {
			bottomText = `${performance.label} / ${price}€`;
		} else {
			bottomText = `${performance.label} / Δωρεάν Προβολή`;
		}
		packageElement.innerHTML = bottomText;
		packageContainer.classList.remove('no-selection');
	}

	render();

	if (viewersElement) {
		viewersElement.addEventListener('change', function() {
			render();
		});
	}

	// Handle checkout option change
	for (const option of checkoutOptions) {
		option.addEventListener('change', function() {
			render();
		});
	}

	setupPaymentForm(form, {
		onValidationError: function() {
			let label = `${performance.label} | ${performance.start}`;

			indevGtag.sendEvent('vod purchase', {
				'event_category': 'form_error',
				'event_label': label,
			});
		},
	});
}
