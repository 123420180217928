function zeroPad(n) {
	return n.toString().padStart(2, '0');
}

function getTimestamp() {
	return Math.floor(Date.now() / 1000);
}

function calculateRemaining(targetTimestamp) {
	const secondsInMinute = 60;
	const secondsInHour = secondsInMinute * 60;
	const secondsInDay = secondsInHour * 24;

	let totalSeconds = targetTimestamp - getTimestamp();
	let remainingSeconds = totalSeconds;

	const remainingDays = Math.floor(remainingSeconds / secondsInDay);
	remainingSeconds = remainingSeconds % secondsInDay;

	const remainingHours = Math.floor(remainingSeconds / secondsInHour);
	remainingSeconds = remainingSeconds % secondsInHour;

	const remainingMinutes = Math.floor(remainingSeconds / secondsInMinute);
	remainingSeconds = remainingSeconds % secondsInMinute;

	return {
		totalSeconds: totalSeconds,
		days: remainingDays,
		hours: remainingHours,
		minutes: remainingMinutes,
		seconds: remainingSeconds,
	};
}

export function setupCountdown($container, seconds) {
	const targetTimestamp = getTimestamp() + seconds;

	const $days = $container.querySelector('.days');
	const $hours = $container.querySelector('.hours');
	const $minutes = $container.querySelector('.minutes');
	const $seconds = $container.querySelector('.seconds');

	function updateCounter() {
		const remaining = calculateRemaining(targetTimestamp);

		$days.innerHTML = zeroPad(remaining.days);
		$hours.innerHTML = zeroPad(remaining.hours);
		$minutes.innerHTML = zeroPad(remaining.minutes);
		$seconds.innerHTML = zeroPad(remaining.seconds);

		if (remaining.totalSeconds > 0) {
			setTimeout(updateCounter, 1000);
		}
	}
	setTimeout(updateCounter, 1000);
}

