import React from 'react';
import moment from 'moment';


export default class Day extends React.Component {
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	hasEvents() {
		return this.props.scheduler.eventsFor(this.props.day).length > 0;
	}

	onClick() {
		if (this.hasEvents()) {
			this.props.onClick(this.props.day);
		}
	}

	render() {
		// determine classes
		let className = 'day';

		if (this.props.day.isSame(this.props.activeMonth, 'month')) {
			className = `${className} within-month`;
		} else {
			className = `${className} outside-month`;
		}

		if (this.props.day.isSame(moment(), 'day')) {
			className = `${className} today`;
		}

		if (this.hasEvents()) {
			className = `${className} has-events`;
		}

		return <li className={className} onClick={this.onClick}>
			<div className="number">
				{this.props.day.format('D')}
			</div>
			<ul className="dots">{this.renderDots()}</ul>
		</li>;
	}

	renderDots() {
		const events = this.props.scheduler.eventsFor(this.props.day);

		let hasLocal = false;
		let hasLivestream = false;
		for (const event of events) {
			if (event.is_local) {
				hasLocal = true;
			} else {
				hasLivestream = true;
			}
		}

		const dots = [];
		if (hasLocal) {
			dots.push(<li key="local" className="local" />);
		}
		if (hasLivestream) {
			dots.push(<li key="livestream" className="livestream" />);
		}

		return dots;
	}
}
