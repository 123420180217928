import $ from 'jquery';
import setupPaymentForm from 'payment-form';
import indevGtag from 'analytics/tracker';

const donationForm = document.querySelector('.donation-form');

if (donationForm) {
	$('input[type="radio"]').each(function(_, radio) {
		if ($(radio).is(':checked')) {
			$(radio).parent().addClass('checked');
		}
	});

	$('input[name="payment_type"]').on('change', function(event) {
		const paymentType = event.target.value;

		if (paymentType === 'bank_transfer') {
			$(donationForm).addClass('bank');
		} else {
			$(donationForm).removeClass('bank');
		}
	});

	setupPaymentForm(donationForm);
}


const $successData = $('.success-donation-data');
if ($successData.length > 0) {
	const price = $successData.data('payment-amount');
	const paymentType = $successData.data('payment-type');
	const prettyPrice = parseInt(price, 10);
	let prettyType = '';

	if (paymentType === 'bank_transfer') {
		prettyType = 'bank';
	} else {
		prettyType = 'card';
	}

	indevGtag.sendEvent('donation', {
		'event_category': 'form_submit',
		'event_label': '(not set)',
		'value': prettyPrice,
		'payment_type': prettyType,
	});
}
