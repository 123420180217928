import $ from 'jquery';
import _ from 'underscore';
import 'jquery-ajaxit';

const $changePasswordForm = $('.change-password-form');
const submitButton = $changePasswordForm.find('button[type="submit"]');

$changePasswordForm.ajaxit({
	onSuccess: function(data) {
		submitButton.text(data.message);
		$changePasswordForm.find(':input').prop('disabled', true);
	},
});

const $passwordInputs =
	$('.field.new_password1 input, .field.new_password2 input');

function changePasswordMatch() {
	$passwordInputs.on('input', _.debounce(function(event) {
		const input1 = event.target;
		let input2 = '';

		for (let input of $passwordInputs) {
			if (input1 !== input) {
				input2 = input;
			}
		}

		if ((input2.value.length > 0) && (input1.value.length > 0)) {
			if (input1.value !== input2.value) {
				$passwordInputs.parent().addClass('password-mismatch');
				$passwordInputs.parent().removeClass('password-match');
			} else {
				$passwordInputs.parent().addClass('password-match');
				$passwordInputs.parent().removeClass('password-mismatch');
			}
		} else {
			$passwordInputs.parent().removeClass(
				'password-match password-mismatch'
			);
		}
	}, 500));
}

changePasswordMatch();
