import moment from 'moment';
import 'moment/locale/el';

// remove accents from months
moment.updateLocale('el', {
	monthsNominativeEl: [
		'Ιανουαριος', 'Φεβρουαριος', 'Μαρτιος', 'Απριλιος', 'Μαιος', 'Ιουνιος',
		'Ιουλιος', 'Αυγουστος', 'Σεπτεμβριος', 'Οκτωβριος', 'Νοεμβριος',
		'Δεκεμβριος',
	],
	monthsGenitiveEl: [
		'Ιανουαριου', 'Φεβρουαριου', 'Μαρτιου', 'Απριλιου', 'Μαιου', 'Ιουνιου',
		'Ιουλιου', 'Αυγουστου', 'Σεπτεμβριου', 'Οκτωβριου', 'Νοεμβριου',
		'Δεκεμβριου',
	],
	weekdays: [
		'Κυριακη', 'Δευτερα', 'Τριτη', 'Τεταρτη', 'Πεμπτη', 'Παρασκευη',
		'Σαββατο',
	],
});

// activate locale
moment.locale('el');
