import React from 'react';

import Calendar from 'calendar/components/calendar';
import Filter from 'calendar/components/filter';


// calendar for multiple plays
export default class FullCalendar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isFilterOpen: false,
		};
	}
	render() {
		const overlayExtraClass = this.state.isFilterOpen ? 'open' : '';
		return <div>
			<Calendar scheduler={this.props.scheduler} >
				<div className="calendar-top">
					<div
						className={`calendar-overlay ${overlayExtraClass}`}
						onClick={() => this.handleOverlayClick()}
					/>
					<Filter
						choices={this.props.plays}
						selected={this.props.scheduler.selected}
						onSelect={this.handleFilterSelect.bind(this)}
						open={this.state.isFilterOpen}
						onOpen={() => this.setState({isFilterOpen: true})}
						onClose={() => this.setState({isFilterOpen: false})}
					/>
				</div>
			</Calendar>
		</div>;
	}

	handleOverlayClick() {
		if (this.state.isFilterOpen) {
			this.setState({isFilterOpen: false});
		}
	}

	handleFilterSelect(id) {
		this.props.scheduler.selectPlay(id);
		this.setState({isFilterOpen: false});
		this.forceUpdate();
	}
}
