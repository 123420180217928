import videojs from 'video.js';

const Menu = videojs.getComponent('Menu');
const MenuItem = videojs.getComponent('MenuItem');
const MenuButton = videojs.getComponent('MenuButton');


class QualityManager extends videojs.EventTarget {
	constructor(levels) {
		super();
		this.levels = levels;
		this.selected = 'Auto'; // the quality the user has selected

		this.levels.on('addqualitylevel', () => {
			this.trigger('update');
		});
		this.levels.on('removequalitylevel', () => {
			this.trigger('update');
		});
		this.levels.on('change', () => {
			this.trigger('update');
		});
	}

	getChoices() {
		const labels = ['Auto'];
		for (const level of Array.from(this.levels)) {
			labels.push(`${level.height}p`);
		}

		return labels.map(label => ({
			label: label,
			selected: label === this.selected,
		}));
	}

	getActive() {
		// Active is the currently playing quality and may be different than
		// the one the user has selected.
		if (this.levels.selectedIndex === -1) {
			return '';
		} else {
			const choices = this.getChoices();
			return choices[this.levels.selectedIndex + 1].label;
		}
	}

	select(label) {
		this.selected = label;

		if (label === 'Auto') {
			Array.from(this.levels).forEach(level => {
				level.enabled = true;
			});
		} else {
			Array.from(this.levels).forEach(level => {
				level.enabled = `${level.height}p` === label;
			});
		}

		this.trigger('update');
	}
}


class QualitySelectorMenuItem extends MenuItem {
	constructor(player, options) {
		const parentOptions = {
			label: options.label,
			selected: options.selected,
			selectable: true,
			multiSelectable: false,
		};
		super(player, parentOptions);

		this.label = options.label;
		this.qualityManager = options.qualityManager;
	}

	handleClick() {
		super.handleClick();
		this.qualityManager.select(this.label);
	}
}

class QualitySelectorMenuButton extends MenuButton {
	constructor(player, options) {
		super(player, options);
		this.qualityManager = new QualityManager(player.qualityLevels());

		this.update();
		this.qualityManager.on('update', this.update.bind(this));
	}

	buildWrapperCSSClass() {
		return `vjs-custom-quality-selector ${super.buildWrapperCSSClass()}`;
	}

	update() {
		if (typeof this.qualityManager === 'undefined') {
			return;
		}

		this.updateLabel();
		super.update();
	}

	updateLabel() {
		this.labelEl.innerHTML = this.qualityManager.getActive();
	}

	createEl() {
		const el = super.createEl();

		this.labelEl = document.createElement('div');
		this.labelEl.className = 'value';
		el.appendChild(this.labelEl);

		return el;
	}

	createMenu() {
		const menu = new Menu(this.player());

		for (const choice of this.qualityManager.getChoices()) {
			const item = new QualitySelectorMenuItem(this.player(), {
				label: choice.label,
				selected: choice.selected,
				qualityManager: this.qualityManager,
			});
			menu.addChild(item);
		}

		return menu;
	}

	dispose() {
		this.qualityManager.off();
		this.qualityManager = null;
		this.labelEl = null;
		super.dispose();
	}
}
videojs.registerComponent(
	'QualitySelectorMenuButton',
	QualitySelectorMenuButton
);
