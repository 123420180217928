import React from 'react';


export default class Filter extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const openClass = this.props.open ? 'open' : '';
		return <div className="calendar-filter">
			<div
				className={`head ${openClass}`}
				onClick={event => this.headClickHandler(event)}
			>
				{this.renderHead()}
				<div className="trigger-icon"/>
			</div>
			<div className="dropdown-wrapper">
				<ul className={`dropdown ${openClass}`}>
					{this.renderChoices()}
				</ul>
			</div>
		</div>;
	}

	renderChoices() {
		if (this.props.choices.length === 0) {
			return null;
		}

		const emptyChoice = ({id: null, title: 'ΟΛΕΣ ΟΙ ΠΑΡΑΣΤΑΣΕΙΣ'});
		const concreteChoices = this.props.choices.map(choice => {
			return {
				id: choice.id,
				title: choice.title,
			};
		});
		const allChoices = [emptyChoice].concat(concreteChoices);

		return allChoices.map(choice =>
			<li
				key={choice.id}
				className="choice"
				onClick={event => this.choiceClickHandler(choice.id, event)}
			>
				<span className="label">{choice.title}</span>
			</li>
		);
	}

	renderHead() {
		let text;
		if (this.props.selected === null) {
			text = 'ΕΠΙΛΕΞΤΕ ΠΑΡΑΣΤΑΣΗ';
		} else {
			text = this.props.choices
				.find(choice => choice.id === this.props.selected)
				.title;
		}
		return <span>{text}</span>;
	}

	choiceClickHandler(id, event) {
		event.stopPropagation();
		this.props.onSelect(id);
	}

	headClickHandler(event) {
		event.stopPropagation();
		if (this.props.open) {
			this.props.onClose();
		} else {
			this.props.onOpen();
		}
	}
}
