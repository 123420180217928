import 'core-js';
import 'whatwg-fetch';

import 'sentry';

import 'calendar/i18n';
import 'calendar/mount';

import 'tabs';
import 'main-menu';
import 'play-detail';
import 'newsletter';
import 'contact';
import 'collection';
import 'responsive-menu';
import 'slideshow';
import 'payment-form';
import 'season-index';
import 'about';
import 'corporate-contact';
import 'login-form';
import 'select-membership';
import 'change-password';
import 'edit-profile';
import 'benefits';
import 'registration-form';
import 'renew-form';
import 'donation-form';
import 'gift-form';
import 'membership-admin';
import 'free-plays';
import 'change-membership';
import 'accept-cookies';
import 'more-toggle';
import 'people-lightbox';
import 'course-form';
import 'purchases';
import 'livestream/checkout';
import 'livestream/success';
import 'livestream/player';
import 'vod/checkout';
import 'vod/success';

/*
	Initialize Google's analytics tracking
*/
import 'analytics/tracker';
import 'analytics/social';
import 'analytics/header';
import 'analytics/various';
import 'analytics/plays';
import 'analytics/livestream';
import 'analytics/vod';
