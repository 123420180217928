import $ from 'jquery';
import 'jquery-ajaxit';
import indevGtag from 'analytics/tracker';

const $newsletterForm = $('.newsletter-registration-form');

const submitButton = $newsletterForm.find('button[type="submit"]');

$newsletterForm.ajaxit({
	onSuccess: function() {
		submitButton.text('ΕΥΧΑΡΙΣΤΟΥΜΕ!');
		$newsletterForm.find(':input').prop('disabled', true);
		indevGtag.sendEvent('newsletter', {
			'event_category': 'form_submit',
			'event_label': '(not set)',
		});
	},
	onValidationError: function() {
		window.grecaptcha.reset();
		indevGtag.sendEvent('newsletter', {
			'event_category': 'form_error',
			'event_label': '(not set)',
		});
	},
});

window.onNewsletterSubmit = function() {
	submitButton.submit();
};
