const description = document.querySelector('.long-description');

function toggleButton() {
	description.classList.toggle('open');
}

// play more button
const descriptionTrigger = document.querySelector('.more-toggle');
if (descriptionTrigger) {
	descriptionTrigger.addEventListener('click', toggleButton);
}
