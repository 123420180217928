import $ from 'jquery';
import 'jquery-ajaxit';

const $courseForm = $('.js-course-form');

const $submitButton = $courseForm.find('button[type="submit"]');

const $cvInput = $('.field.cv input');
const $cvInputLabel = $('.field.cv label');
const $cvInputLabelText = $('.field.cv label').text();

const $summaryInput = $('.field.summary input');
const $summaryInputLabel = $('.field.summary label');
const $summaryInputLabelText = $('.field.summary label').text();

const $openingSceneInput = $('.field.opening_scene input');
const $openingSceneInputLabel = $('.field.opening_scene label');
const $openingSceneInputLabelText = $('.field.opening_scene label').text();

function updateLabelText(event, label, labelText) {
	let fileName = '';
	fileName = event.target.value.split('\\').pop();

	if (fileName) {
		label.text(fileName);
	} else {
		label.text(labelText);
	}
}

$cvInput.change(function(event) {
	updateLabelText(event, $cvInputLabel, $cvInputLabelText);
});

$summaryInput.change(function(event) {
	updateLabelText(event, $summaryInputLabel, $summaryInputLabelText);
});

$openingSceneInput.change(function(event) {
	updateLabelText(
		event, $openingSceneInputLabel, $openingSceneInputLabelText
	);
});

$courseForm.ajaxit({
	onSuccess: function(data) {
		$submitButton.text(data.message);
		$courseForm.find(':input').prop('disabled', true);
	},
});
