import React from 'react';
import ReactDom from 'react-dom';
import $ from 'jquery';

import ezps from 'ezps';

import indevGtag from 'analytics/tracker';
import Scheduler from 'calendar/scheduler';
import PlayCalendar from 'calendar/components/play-calendar';


// Mount calendar.
const mount = document.querySelector('.js-play-detail.schedule');
if (mount) {
	const schedule = JSON.parse(mount.dataset.schedule);
	const bookingUrl = mount.dataset.booking_url;
	const args = {
		scheduler: new Scheduler(schedule),
		bookingUrl: bookingUrl,
		playSlug: mount.dataset.play_slug,
	};

	ReactDom.render(<PlayCalendar {...args}/>, mount);
}

// Initialize galleries.
const $pane1 = $('.gallery-tabset .panes > li:nth-child(1) .slide');
const $pane2 = $('.gallery-tabset .panes > li:nth-child(2) .slide');

if (($pane1.length > 0) || ($pane2.length > 0)) {
	ezps($pane1, {});
	ezps($pane2, {});

}

// video of the entire play (members only)
ezps('.play-section .play-video-lightbox');

// analytics
// book ticket CTA
function trackBookingLink(event) {
	const slug = event.currentTarget.dataset.playSlug;
	const action = event.currentTarget.dataset.eventAction;

	indevGtag.sendEvent(action, {
		'event_category': 'button_click',
		'event_label': `${slug} | buy`,
	});
	indevGtag.sendPageView({
		'page_path' : `_/vpv/viva/${slug}`,
	});
}
const bookingLinks = document.querySelectorAll('.js-booking-cta');
for (const bookingLink of bookingLinks) {
	bookingLink.addEventListener('click', trackBookingLink);
}
