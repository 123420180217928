import $ from 'jquery';
import initializeItems from 'login-form';
import ezps from 'ezps';

const $selectButton = $('.season-index');

$selectButton.each(function(index, el) {
	const $el = $(el);
	const $section = $el.closest('.js-filter-section');
	const $parent = $section.find('.js-archive > ul');
	const $allPlays = $section.find('.js-past-item').clone();

	$el.on('change', function(event) {
		const visiblePlay = $(event.target).val();

		$parent.empty();
		$allPlays.each(function(index, el) {
			const $el = $(el);
			const $clone = $el.clone();

			if (visiblePlay === 'default' || $el.hasClass(visiblePlay)) {
				$parent.append($clone);
			}
		});

		const $collection = $('.js-collection');
		let $collectionItems = $collection.find('ul > li');

		if ($collectionItems.length < 9) {
			$collection.removeClass('with-button');
			$collection.addClass('without-button');
		} else {
			$collection.removeClass('without-button');
			$collection.addClass('with-button');
		}

		// Initialize cloned items
		$collectionItems.each(function(_, elem) {
			ezps($(elem));
		});
		initializeItems($parent);
		ezps($parent.find('.play-video-lightbox'));
		ezps('.js-people > li');
	});
});
