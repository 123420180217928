import React from 'react';


class DayButton extends React.Component {
	constructor() {
		super();

		this.focusDay = this.focusDay.bind(this);
	}

	focusDay() {
		this.props.onFocusDay(this.props.day);
	}

	render() {
		if (this.props.day === null) {
			return null;
		}

		return <button
			className={this.props.className}
			onClick={this.focusDay}
		/>;
	}
}

export default class DayDetails extends React.Component {
	render() {
		const day = this.props.day;
		const events = this.props.scheduler.eventsFor(day);


		const rendered = events.map(event => {
			const className = event.is_local ? 'local' : 'livestream';
			const key = `${event.title}-${event.datetime.unix()}-${className}`;

			return <li key={key} className={className}>
				{this.renderRowContents(event)}
			</li>;
		});

		return <div className="day-details" onClick={this.props.onClose}>
			<div className="container" onClick={e => e.stopPropagation()}>
				<button className="close" onClick={this.props.onClose} />
				<DayButton
					className="day-prev"
					day={this.props.scheduler.firstDayBefore(day)}
					onFocusDay={this.props.onFocusDay}
				/>
				<DayButton
					className="day-next"
					day={this.props.scheduler.firstDayAfter(day)}
					onFocusDay={this.props.onFocusDay}
				/>

				<div className="date">{day.format('D MMMM')}</div>
				<ul>{rendered}</ul>
			</div>
		</div>;
	}

	renderRowContents(event) {
		const rowText = `${event.datetime.format('HH:mm')} - ${event.title}`;
		if (this.props.showLinks) {
			return <a href={event.url} className="time-row">{rowText}</a>;
		} else {
			return <div className="time-row">{rowText}</div>;
		}
	}
}
