import $ from 'jquery';
import 'jquery-ajaxit';

const $editButton = $('.js-profile-edit-button');
const $form = $('.edit-profile-form');

$editButton.on('click', function() {
	$('.personal-info').toggleClass('edit');
});

$form.ajaxit({
	onSuccess: function(data) {

		$('.personal-info').toggleClass('edit');
		const profileData = data.profileData;
		$('.field.full_name').find('.value .text').text(profileData.full_name);
		$('.field.telephone').find('.value .text').text(profileData.telephone);
		$('.field.address').find('.value .text').text(profileData.address);

		$form.find('button[type="submit"]').prop('disabled', false);
	},
});
