import React from 'react';
import ReactDom from 'react-dom';

import Scheduler from 'calendar/scheduler';
import FullCalendar from 'calendar/components/full-calendar';

const mount = document.querySelector('.js-full-calendar');

if (mount) {
	const schedule = JSON.parse(mount.dataset.schedule);
	const plays = {};
	for (let occurrence of schedule) {
		plays[occurrence.play_id] = {
			id: occurrence.play_id,
			title: occurrence.title,
		};
	}

	const scheduler = new Scheduler(schedule);

	const args = {
		plays: Object.values(plays),
		scheduler: scheduler,
	};

	ReactDom.render(<FullCalendar {...args}/>, mount);
}
