import videojs from 'video.js';
import 'videojs-contrib-quality-levels';

import './quality-selector';
import {setupCountdown} from 'countdown';

function initPlayer(playerId) {
	const $player = document.getElementById(playerId);
	if ($player === null) {
		return;
	}

	const player = videojs($player, {
		controls: true,
		liveui: true,
		fill: true,
		html5: {
			hls: {
				withCredentials: true,
				overrideNative: !videojs.browser.IS_SAFARI,
			},
			nativeAudioTracks: false,
			nativeVideoTracks: false,
		},
		sources: [
			{
				src: $player.dataset.source,
				type: 'application/x-mpegURL',
			},
		],
	});
	player.controlBar.addChild(
		'QualitySelectorMenuButton',
		{},
		player.controlBar.children().length - 1,
	);

	return player;
}

const $playerContainer = document.querySelector('.livestream-player-container');

function schedule(seconds, callback) {
	if (seconds !== null) {
		setTimeout(callback, seconds * 1000);
	}
}
function setStatus(status) {
	$playerContainer.dataset.status = status;
}

if ($playerContainer) {
	const player = initPlayer('livestream');

	// schedule state transitions
	const timings = JSON.parse($playerContainer.dataset.timings);
	schedule(timings.starting_in, function() {
		setStatus('starting');
	});
	schedule(timings.started_in, function() {
		setStatus('started');
	});
	schedule(timings.ending_in, function() {
		setStatus('ending');
	});
	if (player) {
		player.on('ended', function() {
			setStatus('ended');
		});
	}
	schedule(timings.next_in, function() {
		document.location.reload();
	});

	// setup counter
	if (timings.started_in !== null) {
		setupCountdown(
			document.querySelector('.js-countdown'),
			timings.started_in
		);
	}
}
