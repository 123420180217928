import $ from 'jquery';

const $radioContainer = $('.field.membership_type');
const $button = $radioContainer.find('.change-memberships');

$button.on('click', function(event) {
	event.preventDefault();
	if (!$radioContainer.hasClass('show-supporter')) {
		$radioContainer.find('.membership-package.supporter label').click();
	}
	$radioContainer.toggleClass('show-supporter');
});
