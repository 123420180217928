import $ from 'jquery';

const $collections = $('.collection');

$collections.each(function(i, collection) {
	const $collection = $(collection);
	const $toggle = $collection.find('button.toggle');

	$toggle.on('click', function() {
		$collection.toggleClass('open');
	});
});
