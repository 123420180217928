import indevGtag from 'analytics/tracker';

const rentalData = document.querySelector('.js-rental-data');
if (rentalData) {
	const playSlug = rentalData.dataset.playSlug;
	const viewers = rentalData.dataset.viewers;
	const vivaCheckout = JSON.parse(rentalData.dataset.vivaCheckout);
	const purchaseCheckout = JSON.parse(
		rentalData.dataset.purchaseCheckout
	);
	const priceStr = rentalData.dataset.price.replace(',', '.');
	const price = Math.round(parseFloat(priceStr));

	let purchaseType;

	if (vivaCheckout) {
		purchaseType = 'viva';
	} else {
		purchaseType = purchaseCheckout ? 'paid' : 'free';
	}

	// Only for free livstreams price should always be null
	let finalPrice = (purchaseType === 'free') ? NaN : price;

	indevGtag.sendEvent('vod purchase', {
		event_category: 'form_submit',
		event_label: `${playSlug}`,
		value: finalPrice,
		vod_purchase_type: purchaseType,
		vod_viewers: viewers,
		vod_viewers_metric: viewers,
	});
}
