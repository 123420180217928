import indevGtag from 'analytics/tracker';

const vodDonation = document.querySelector('.js-vod-donation');
if (vodDonation) {
	vodDonation.addEventListener('click', function() {
		indevGtag.sendEvent('vod donation', {
			'event_category': 'button_click',
			'event_label': 'donation',
		});
	});
}
