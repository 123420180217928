import $ from 'jquery';
import Cookie from 'js-cookie';


const cookieValue = Cookie.get('accepted_terms');
const $page = $('.terms-page');

if ((cookieValue !== 'yes') && $page.length === 0) {
	const $cookies = $('.accept-cookies');
	$cookies.addClass('show');

	$cookies.find('button').on('click', function() {
		Cookie.set('accepted_terms', 'yes', {expires: 365 * 5});
		$cookies.removeClass('show');
	});
}
