import $ from 'jquery';
import 'jquery-ajaxit';

const $form = $('.js-free-plays-form');
const $submitButton = $form.find('button[type="submit"]');
const $freePlays = $('.free-plays');

$form.ajaxit({
	onSuccess: function(data) {
		$submitButton.prop('disabled', true);
		$submitButton.text(data.message);
		$freePlays.text(data.plays_left);
	},
});
