import IndevGtag from 'analytics/IndevGtag';

const gtagParams = {
	'transport_type': 'beacon',
	'custom_map': {
		'dimension2': 'hit_id',
		'dimension3': 'session_id',
		'dimension4': 'user_id',
		'dimension6': 'payment_type',
		'dimension7': 'membership_type',
		'dimension8': 'gift_type',
		'dimension9': 'livestream_viewers',
		'dimension10': 'livestream_purchase_type',
		'dimension11': 'vod_viewers',
		'dimension12': 'vod_purchase_type',
		'metric1': 'livestream_viewers_metric',
		'metric2': 'vod_viewers_metric',
	},
	'linker': {
		'domains': ['www.viva.gr', 'viva.gr'],

	},
};
const userParams = {
	'persistent': {
		'dimension2': 'uuid',
		'dimension3': 'uuid',
	},
};
const indevGtag = new IndevGtag('UA-26912627-1', gtagParams, userParams);

export default indevGtag;
