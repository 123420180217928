import $ from 'jquery';
import setupPaymentForm from 'payment-form';
import indevGtag from 'analytics/tracker';

const form = document.querySelector('.js-registration-form');

if (form) {
	setupPaymentForm(form);
}

const $successData = $('.success-registration-data.js-registration');
if ($successData.length > 0) {
	const price = $successData.data('payment-amount');
	const type = $successData.data('registration-type');
	const prettyPrice = parseInt(price, 10);

	indevGtag.sendEvent('registration', {
		'event_category': 'form_submit',
		'event_label': type,
		'value': prettyPrice,
		'membership_type': type,
	});
}


const $successGift = $('.success-registration-data.js-gift-membership');
if ($successGift.length > 0) {
	const price = $successGift.data('payment-amount');
	const prettyPrice = parseInt(price, 10);
	const membershipType = $successGift.data('registration-type');

	indevGtag.sendEvent('gift membership', {
		'event_category': 'form_submit',
		'event_label': membershipType,
		'value': prettyPrice,
		'gift_type': membershipType,
	});
}
