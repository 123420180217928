import $ from 'jquery';
import 'jquery-ajaxit';
import indevGtag from 'analytics/tracker';

function scrollToFirstErrorField() {
	// Find and scroll to the first field with errors
	const doc = $(document);
	const top = (
		window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
	const errorTop = $('.field.error').offset().top;

	if (top > errorTop) {
		$('html, body').animate({
			scrollTop: errorTop,
		}, 500);
	}
}

function checkPaymentStatus(url) {
	$.ajax({
		method: 'get',
		url: url,
		success: function(data) {
			if (data.action === 'poll') {
				window.setTimeout(checkPaymentStatus, 800, url);
			} else if (data.action === 'redirect') {
				window.location = data.url;
			} else {
				window.location = data.url;
			}
		},
	});
}

function formSubmit(form) {
	const $form = $(form);
	$(form).addClass('polling');
	// Checkout request (with validated form data)
	$.ajax({
		url: $form.data('payment-endpoint'),
		type: $form.attr('method'),
		data: $form.serialize(),
		success: function(data) {
			if (data.action === 'poll') {
				checkPaymentStatus(data.url);
			} else if (data.action === 'redirect') {
				// fake processing delay
				window.setTimeout(function() {
					window.location = data.url;
				}, 300);
			}
		},
	});
}

export default function setupPaymentForm(form, options={}) {
	$(form).ajaxit({
		getResponseStatus: function(data) {
			return data.status;
		},
		onSuccess: function() {
			return formSubmit(form);
		},
		onValidationError: function() {
			if (options.onValidationError) {
				options.onValidationError();
			} else {
				// TODO: move these on options.onValidationError of each call
				const selector = document.querySelector('label.checked');
				let memberPackage;
				if (selector) {
					const attribute = 'data-membership-package';
					memberPackage = selector.getAttribute(attribute);
				} else {
					memberPackage = '(not set)';
				}

				let action;
				if (form.classList.contains('js-registration-form')) {
					action = 'register';
				} else if (form.classList.contains('donation-form')) {
					action = 'donation';
					memberPackage = '(not set)';
				} else if (form.classList.contains('js-gift-form')) {
					action = 'gift membership';
				}

				indevGtag.sendEvent(action, {
					'event_category': 'form_error',
					'event_label': memberPackage,
				});
			}

			scrollToFirstErrorField();
		},
	});
}
