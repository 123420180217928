import indevGtag from 'analytics/tracker';

const freeButton = document.querySelector('.free .benefits-button');
if (freeButton) {
	freeButton.addEventListener('click', function() {
		indevGtag.sendEvent('επιλογή συνδρομής', {
			'event_category': 'button_click',
			'event_label': 'προνόμια συνδρομής χρήστη',
		});
	});
}

const memberButton = document.querySelector('.member .benefits-button');
if (memberButton) {
	memberButton.addEventListener('click', function() {
		indevGtag.sendEvent('επιλογή συνδρομής', {
			'event_category': 'button_click',
			'event_label': 'προνόμια συνδρομής μέλους',
		});
	});
}

const friendButton = document.querySelector('.premium .benefits-button');
if (friendButton) {
	friendButton.addEventListener('click', function() {
		indevGtag.sendEvent('επιλογή συνδρομής', {
			'event_category': 'button_click',
			'event_label': 'προνόμια συνδρομής φίλου',
		});
	});
}

const supporterButton = document.querySelector('.supporter .benefits-button');
if (supporterButton) {
	supporterButton.addEventListener('click', function() {
		indevGtag.sendEvent('επιλογή συνδρομής', {
			'event_category': 'button_click',
			'event_label': 'προνόμια συνδρομής υποστηρικτή',
		});
	});
}

const subsriptionButton = document.querySelector('.change-memberships');
if (subsriptionButton) {
	subsriptionButton.addEventListener('click', function() {
		let supporter = subsriptionButton.querySelector('.supporter-text');
		let supporterStyle = window.getComputedStyle(supporter, null);
		let member = subsriptionButton.querySelector('.simple-membership-text');
		let memberStyle = window.getComputedStyle(member, null);

		if (supporterStyle.display !== 'block') {
			indevGtag.sendEvent('επιλογή συνδρομής', {
				'event_category': 'button_click',
				'event_label': supporter.innerText.toLowerCase(),
			});
		} else if (memberStyle.display !== 'block') {
			indevGtag.sendEvent('επιλογή συνδρομής', {
				'event_category': 'button_click',
				'event_label': member.innerText.toLowerCase(),
			});
		}
	});
}

// Εγραφή/Δώρισε μια συνδρομή  member, friend, supporter
let selector = '.memberpage-bottom .cta-button';
const registerButtons = document.querySelectorAll(selector);
if (registerButtons.length > 0) {
	registerButtons.forEach(function(button) {
		button.addEventListener('click', function(event) {
			const text = event.currentTarget.dataset.label;

			indevGtag.sendEvent('πώς θα θέλατε να συνεχίσετε', {
				'event_category': 'button_click',
				'event_label': text,
			});
		});
	});
}

selector = '.memberpage-bottom .js-corporate-contact.cta-button';
const corporateContact = document.querySelector(selector);
if (corporateContact) {
	corporateContact.addEventListener('click', function(event) {
		const text = event.currentTarget.dataset.label;
		indevGtag.sendEvent('επικοινωνήστε μαζί μας', {
			'event_category': 'button_click',
			'event_label': text,
		});
	});
}

const englishBuyLinks = document.querySelectorAll('.js-english-buy');
if (englishBuyLinks.length > 0) {
	englishBuyLinks.forEach(function(link) {
		const linkUrl = link.href.split('?');

		link.addEventListener('click', function() {
			indevGtag.sendEvent('various', {
				'event_category': 'link_outbound',
				'event_label': `buy tickets online | ${linkUrl}`,
			});
		});
	});
}

// livestream landing purchase CTA

// book livestream CTA
function trackLivestreamLink(event) {
	const button = event.currentTarget;
	const slug = button.dataset.playSlug;
	let action = null;
	if (button.classList.contains('js-hero')) {
		action = 'hero';
	} else if (button.classList.contains('js-calendar')) {
		action = 'calendar';
	} else if (button.classList.contains('js-landing')) {
		action = 'tile';
	} else if (button.classList.contains('js-upcoming-link')) {
		action = 'upcoming';
	} else if (button.classList.contains('js-player')) {
		action = 'live-player';
	}

	indevGtag.sendEvent(action, {
		'event_category': 'button_click',
		'event_label': `${slug} | buy_stream`,
	});
}
const livestreamLinks = document.querySelectorAll('.js-livestream-cta');
for (const livestreamLink of livestreamLinks) {
	livestreamLink.addEventListener('click', trackLivestreamLink);
}

// Video on demand landing purchase CTA

function trackVodLink(event) {
	const button = event.currentTarget;
	const slug = button.dataset.playSlug;
	let action = null;

	if (button.classList.contains('js-hero')) {
		action = 'hero';
	} else if (button.classList.contains('js-calendar')) {
		action = 'calendar';
	} else if (button.classList.contains('js-landing')) {
		action = 'tile';
	} else if (button.classList.contains('js-upcoming-link')) {
		action = 'upcoming';
	}

	indevGtag.sendEvent(action, {
		'event_category': 'button_click',
		'event_label': `${slug} | buy_vod`,
	});
}

const vodLinks = document.querySelectorAll('.js-vod-cta');
for (const vodLink of vodLinks) {
	vodLink.addEventListener('click', trackVodLink);
}
