import moment from 'moment';

function makeKey(datetime) {
	return datetime.clone().startOf('day').toISOString();
}

export default class Scheduler {
	constructor(events) {
		this.selected = null;
		this._eventMap = new Map();
		for (const event of events) {
			event.datetime = moment(event.datetime);
			const key = makeKey(event.datetime);

			if (!this._eventMap.has(key)) {
				this._eventMap.set(key, []);
			}

			let dayEvents = this._eventMap.get(key);
			dayEvents.push(event);
			this._eventMap.set(key, dayEvents);
		}
	}

	getEventMap() {
		const filtered = Array.from(this._eventMap.entries())
			.map(([date, occurrences]) => {
				// Filter out occurrences the user hasn't selected
				return [
					date,
					occurrences.filter(occurrence => {
						return this.selected === null ||
							this.selected === occurrence.play_id;
					}),
				];
			})
			.filter(([_, occurrences]) => {
				// Remove instances where there's no available occurrence
				return occurrences.length > 0;
			});

		return new Map(filtered);
	}

	eventsFor(day) {
		return this.getEventMap().get(makeKey(day)) || [];
	}

	firstDayAfter(targetDay) {
		const days = Array.from(this.getEventMap().keys());

		for (let i = 0; i < days.length; i++) {
			const day = moment(days[i]);

			if (targetDay.isBefore(day)) {
				return day;
			}
		}
		return null;
	}

	firstDayBefore(targetDay) {
		const days = Array.from(this.getEventMap().keys()).reverse();

		for (let i = 0; i < days.length; i++) {
			const day = moment(days[i]);

			if (targetDay.isAfter(day)) {
				return day;
			}
		}
		return null;
	}

	selectPlay(playId) {
		this.selected = playId;
	}
}
