import indevGtag from 'analytics/tracker';

const hook = document.querySelector('ul.social');

if (hook) {
	const socialButtonsFooter = hook.querySelectorAll('.social a');
	socialButtonsFooter.forEach(function(button) {
		button.addEventListener('click', function(event) {
			indevGtag.sendEvent('footer', {
				'event_category': 'social',
				'event_label': event.currentTarget.dataset.medium,
			});
		});
	});
	const socialButtons = hook.querySelectorAll('.contact-sections .social a');
	socialButtons.forEach(function(button) {
		button.addEventListener('click', function(event) {
			indevGtag.sendEvent('κοινωνικά δίκτυα', {
				'event_category': 'social',
				'event_label': event.currentTarget.dataset.medium,
			});
		});
	});
}
