import indevGtag from 'analytics/tracker';

const reservationData = document.querySelector('.js-reservation-data');
if (reservationData) {
	const playSlug = reservationData.dataset.playSlug;
	const startsAt = reservationData.dataset.startsAt;
	const viewers = reservationData.dataset.viewers;
	const vivaCheckout = JSON.parse(reservationData.dataset.vivaCheckout);
	const purchaseCheckout = JSON.parse(
		reservationData.dataset.purchaseCheckout
	);
	const priceStr = reservationData.dataset.price.replace(',', '.');
	const price = Math.round(parseFloat(priceStr));

	let purchaseType;

	if (vivaCheckout) {
		purchaseType = 'viva';
	} else {
		purchaseType = purchaseCheckout ? 'paid' : 'free';
	}

	// Only for free livstreams price should always be null
	let finalPrice = (purchaseType === 'free') ? NaN : price;

	indevGtag.sendEvent('live stream purchase', {
		event_category: 'form_submit',
		event_label: `${playSlug} | ${startsAt}`,
		value: finalPrice,
		livestream_purchase_type: purchaseType,
		livestream_viewers: viewers,
		livestream_viewers_metric: viewers,
	});
}
