import $ from 'jquery';

const $tabs = $('.gallery-tabset .tabs > li');
const $panes = $('.gallery-tabset .panes > li');

$tabs.eq(0).addClass('active');

if ($panes.eq(0).find('.slide:not(:empty)').length > 0) {
	$panes.eq(0).addClass('active');
} else {
	$panes.eq(1).addClass('active');
}

$tabs.on('click', function(event) {
	// make previous inactive
	$tabs.removeClass('active');
	$panes.removeClass('active');

	// make new active
	const index = $tabs.index(event.currentTarget);
	$tabs.eq(index).addClass('active');
	$panes.eq(index).addClass('active');
});
