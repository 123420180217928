import React from 'react';

import Calendar from 'calendar/components/calendar';

// calendar for single play
export default class PlayCalendar extends React.Component {
	render() {
		return <div>
			<Calendar
				scheduler={this.props.scheduler}
				showLinks={false}
			/>
		</div>;
	}
}
